import React from "react";
import i18n from "../../i18n";
import { AvatarImage, Container, SpinnerBig, SpinnerContainer, SpinnerSmall, SpinnerSvgContainer, Text, TextContainer, TextTip } from "./ProcessingLoader.style";
import SvgProcessingIcon from "../../ui/svg/ProcessingIcon";

const LOADING_TEXT_INTERVAL = 3000;

export default class ProcessingLoader extends React.Component {

  constructor(props) {
    super(props);

    this.texts = [
      "processing_text_1",
      "processing_text_2",
      "processing_text_3",
      "processing_text_4",
      "processing_text_5",
    ];

    this.state = {
      imageIsLoaded: false,
      //textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
      textIndex: 0,
    };

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});
  }

  componentDidMount() {
    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        if (this.props.image) {
          this.image.src = this.props.image;
        }
      });
    }

    if (prevProps.isHidden !== this.props.isHidden) {
      if (this.props.isHidden) {
        clearInterval(this.updateTextIndexTimer);
        this.updateTextIndex();
      } else {
        this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
      }
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  }

  render() {
    return <Container hidden={this.props.isHidden}>
      <SpinnerContainer>
        <SpinnerBig>
          <SpinnerSmall />
        </SpinnerBig>

        {!this.state.imageIsLoaded && <SpinnerSvgContainer>
          <SvgProcessingIcon />
        </SpinnerSvgContainer>}

        {this.state.imageIsLoaded && <AvatarImage
          src={this.props.image}
          alt="" />}
      </SpinnerContainer>
      <TextContainer hidden={this.props.hideText}>
        <TextTip dangerouslySetInnerHTML={{__html: i18n.t("processing_tip", {item: this.state.textIndex + 1, total: this.texts.length})}} />
        <Text dangerouslySetInnerHTML={{__html: i18n.t(this.texts[this.state.textIndex]) || ""}} />
      </TextContainer>
    </Container>;
  }
}
